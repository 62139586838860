import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListSubheader from '@mui/joy/ListSubheader';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { closeSidebar } from '../../utils';
import { BtnRedirectMain } from '../../utils/BtnRedirectMain';
import { logout, selectCurrentUser } from '../auth/authSlice';
import { secondBar, selectFirstBar, selectSecondBar, setSecondBar } from './barSlice';

export default function SecondSidebar() {
	const firstBar = useSelector(selectFirstBar);
	const secondBar = useSelector(selectSecondBar);
	const user_data = useSelector(selectCurrentUser);

	const dispatch = useDispatch();
	const location = useLocation();

	const { t } = useTranslation();

	React.useEffect(() => {
		if (firstBar.subMenu.length > 0 && (!secondBar || secondBar.key !== location.pathname.split('/')[1])) {
			// @ts-ignore
			const item = firstBar.subMenu.find(item => item.key === location.pathname.split('/')[1]) || undefined;

			if (item) {
				dispatch(setSecondBar(item));
			}
		}
	}, [dispatch, firstBar, location.pathname, secondBar]);

	return (
		<React.Fragment>
			<Box
				className="SecondSidebar-overlay"
				sx={{
					position: 'fixed',
					zIndex: 9998,
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					bgcolor: 'background.body',
					opacity: 'calc(var(--SideNavigation-slideIn, 0) - 0.2)',
					transition: 'opacity 0.4s',
					transform: {
						xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
						lg: 'translateX(-100%)',
					},
				}}
				onClick={() => closeSidebar()}
			/>
			<Sheet
				className="SecondSidebar"
				sx={{
					position: {
						xs: 'fixed',
						md: 'fixed',
					},
					transform: {
						xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
						md: 'none',
					},
					borderRight: '1px solid',
					borderColor: 'divider',
					transition: 'transform 0.4s',
					zIndex: 9999,
					height: '100dvh',
					top: 0,
					p: 2,
					py: 3,
					flexShrink: 0,
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
				}}>
				<List
					sx={{
						'--ListItem-radius': '8px',
						'--ListItem-minHeight': '32px',
						'--List-gap': '4px',
					}}>
					<ListSubheader role="presentation" sx={{ color: 'text.primary' }}>
						{t(firstBar.name)}
					</ListSubheader>
					<ListItem>
						<Link
							to={`${process.env.REACT_APP_MAIN_URL}/user`}
							style={{
								textDecoration: 'none',
								color: 'inherit',
								width: '100%',
							}}>
							<BtnRedirectMain
								style={{
									textDecoration: 'none',
									color: 'inherit',
									width: '100%',
								}}>
								<ListItemButton>
									<ListItemDecorator>
										<i className="fa-solid fa-home" />
									</ListItemDecorator>
									<ListItemContent>{t('settingsPage.my_profile')}</ListItemContent>
								</ListItemButton>
							</BtnRedirectMain>
						</Link>
					</ListItem>
					{firstBar ? (
						firstBar.subMenu.map((item: secondBar) => {
							if (item.isAdmin === false && user_data?.role === 'admin') {
								return <></>;
							}
							if ((item.isAdmin && user_data?.role !== 'admin') || ([2, 3].includes(parseInt(user_data?.museum?.type)) && (item.key === 'excursions' || item.key === 'guides')) || ((item.key === 'booking' || item.key === 'events') && parseInt(user_data?.museum?.type) === 2)) {
								return <></>;
							}

							return (
								<ListItem key={item.key}>
									<Link
										to={`/${item.key}`}
										style={{
											textDecoration: 'none',
											color: 'inherit',
											width: '100%',
										}}>
										<ListItemButton
											onClick={() => {
												dispatch(setSecondBar(item));
												closeSidebar();
											}}
											{...((secondBar === item && {
												selected: true,
												variant: 'soft',
											}) ||
												{})}>
											<ListItemDecorator>
												<i className={item.icon} />
											</ListItemDecorator>
											<ListItemContent>{t(`${item.key}`)}</ListItemContent>
										</ListItemButton>
									</Link>
								</ListItem>
							);
						})
					) : (
						<></>
					)}
				</List>
				<Box sx={{ pl: 1, mt: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<div>
						<Typography fontWeight="lg" level="body2">
							{/* {user_data?.role} */}
						</Typography>
						<Typography
							level="body2"
							sx={{
								maxWidth: '150px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								display: 'block',
							}}>
							{user_data?.login}
						</Typography>
					</div>
					<IconButton
						variant="plain"
						onClick={() => {
							dispatch(logout());
							// navigator('/login');
							window.location.replace(`${process.env.REACT_APP_MAIN_URL}/logout?redirect=${window.location.origin}/login`);
						}}>
						<i data-feather="log-out" />
					</IconButton>
				</Box>
			</Sheet>
		</React.Fragment>
	);
}
